@font-face {
    font-family: "InterTight";
    src: url("../fonts/InterTight-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "InterTight";
    src: url("../fonts/InterTight-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "InterTight";
    src: url("../fonts/InterTight-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

.hidescroll::-webkit-scrollbar {
    display: none;
}

.hidescroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

body {
    user-select: none;
}

a {
    color: inherit;
}

a:visited {
    color: inherit;
}
